import { extend } from "vee-validate";

extend('between', {
    validate(value, { min, max }) {
        return Number(value) >= Number(min) && Number(value) <= Number(max);
    },
    params: ['min', 'max'],
    message: 'La valeur doit être comprise entre {min} et {max}'
});

extend("requiredModele", {
    computesRequired: true,
    params:['defaultObjet'],
    validate: (value, params) => {
        let obj1 = JSON.stringify(value)
        let obj2 = JSON.stringify(JSON.parse(params.defaultObjet))
        
        return (value && obj1 != obj2) ? true : false
    },
    message: `Le champ {_field_} est obligatoire`
});


extend("requiredReclamant", {
    computesRequired: true,
    params:['defaultObjet'],
    validate: (value, params) => {
        let obj1 = JSON.stringify(value)
        let obj2 = JSON.stringify(JSON.parse(params.defaultObjet))
        return (value && obj1 != obj2) ? true : false
    },
    message: `Le champ {_field_} est obligatoire`
});

extend("requiredPalier", {
    computesRequired: true,
    params:['paliers', 'hasPalier'],
    validate: (value, {paliers, hasPalier}) => {
        let paliersArray = JSON.stringify(JSON.parse(paliers))
        let checkPalier = JSON.stringify(JSON.parse(hasPalier))
        console.log('****************');
        console.log(paliersArray, checkPalier);
        return checkPalier && paliersArray.length <= 0  ? true : false
    },
    message: `Vous devez insérer au moins un palier`
});

extend("requiredCylindres", {
    computesRequired: true,
    params:['defaultObjet'],
    validate: (value, params) => {
        let cylindres = JSON.parse(params.defaultObjet)
        let hasCheckedCylindre = cylindres.find(c => c.checked == true)
        return hasCheckedCylindre === undefined ? false : true
    },
    message: `Veuillez sélectionner au moins une cylindrée`
});

extend("requiredPermis", {
    computesRequired: true,
    params:['defaultObjet'],
    validate: (value, params) => {
        let permis = JSON.parse(params.defaultObjet)
        let hasCheckedPermis = permis.find(c => c.checked == true)
        return hasCheckedPermis === undefined ? false : true
    },
    message: `Veuillez sélectionner au moins un permis`
});

extend("requiredIfChecked", {
    computesRequired: true,
    params:['defaultObjet'],
    validate: (value, params) => {
        let checked = JSON.parse(params.defaultObjet)
        if(checked) {
            return value != null && value != ''
        }
        return true
    },
    message: `Le champ {_field_} est obligatoire.`
});

extend('ibanRules', {
    validate: value => {
        var CODE_LENGTHS = {
            AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
            CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
            FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
            HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
            LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
            MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
            RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,   
            AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
            SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
        };
        var iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
                code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
                digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        var digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
            return letter.charCodeAt(0) - 55;
        });
        // final check
        var checksum = digits.slice(0, 2), fragment;
        for (var offset = 2; offset < digits.length; offset += 7) {
            fragment = String(checksum) + digits.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    },
    message: 'Le {_field_} n\'est pas un IBAN valide'
});

extend('bicRules', {
    validate: value => {
        var bicRegexp = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
        return bicRegexp.test(value);
    },
    message: 'Le {_field_} n\'est pas un BIC valide'
});

export const rules = {
    requiredReclamant: {
        requiredReclamant: true
    },
    requiredModele: {
        requiredModele: true
    },
    requiredCylindres: {
        requiredCylindres: true
    },
    requiredPermis: {
        requiredPermis: true
    },
    ibanRules: {
        ibanRules: true
    },
    bicRules: {
        bicRules: true
    },
    requiredIfChecked: {
        requiredIfChecked: true
    },
    requiredPalier:{
        requiredPalier:true
    }
  };